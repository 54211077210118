<template>
  <main v-if="users" class="mr-4 md:mr-0">
    <trac-loading class="" v-if="loading" />

    <trac-modal v-if="confirmOp" @close="confirmOp = false">
      <h3 class="text-lg font-semibold p-2 text-primaryBlue">Kindly Confirm</h3>
      <div class="my-10 px-20 flex items-center justify-center text-center">
        <p class="text-sm font-semibold">{{ getOpActionText }}</p>
      </div>
      <div class="flex justify-around text-xs">
        <button @click="confirmOp = false" class="border py-2 px-5 rounded-md text-primaryBlue border-primaryBlue">Cancel</button>
        <button @click="updateOps" class="capitalize border py-2 px-5 rounded-md text-red-600 border-red-600">{{ currentOp }}</button>
      </div>
    </trac-modal>

    <trac-back-button>Users &amp; Roles</trac-back-button>
    <div class="flex items-center justify-between my-6 px-2">
      <div class="mt-4">
        <h2 class="text-xs">Settings</h2>
        <h1 class="font-medium mt-2">Users</h1>
      </div>
    </div>
    <div v-if="showingCheckboxes"
      class="flex justify-between text-xs bg-gray-100 rounded-sm p-4">
      <div class=""></div>
      <p class="">You selected <span class=" font-bold">{{userSelected.length}}</span> users.
        <button @click="showConfirmOp" class="text-primaryBlue font-bold">Click to {{currentOp}}</button>
      </p>
      <button class="text-red-600 font-bold" @click="cancelOp">Cancel</button>
    </div>
    <div class="mb-8 mt-12 border rounded-lg overflow-hidden p-6">
      <div class="pb-4 flex flex-col md:items-end md:justify-between">
        <div class="relative rounded-md w-full flex items-center justify-between mb-4 md:mb-0">
          <div class="w-2/5 relative flex items-center justify-between">
            <input type="text" placeholder="Search User"
              class="input-shadow py-2 w-full pl-5 text-xs focus:outline-none leading-7 tracking-wide text-primaryGray"
              v-model="search" />
            <svg class="w-4 h-4 absolute right-0 mr-6" viewBox="0 0 14 14" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="6.56705" cy="6.61686" r="5.39909" stroke="#253B95" stroke-width="1.06786"
                stroke-linecap="round" stroke-linejoin="round" />
              <path d="M13.3156 13.3659L10.3799 10.4302" stroke="#253B95" stroke-width="1.06786" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>

          <div class="flex gap-5">
            <div class="relative" v-if="users.length > 1">
              <button @click.stop @click="toggleBulkUserDropdown"
                class="border border-primaryBlue text-primaryBlue font-semibold py-2 h-full px-4 z-10 rounded text-xs">
                Bulk Action
              </button>
              <div v-if="showingBulkUserDropdown" class="p-2 bg-white absolute z-20 shadow-md text-xs w-48"
                style="top: 100%; right: 50%;">
                <button @click="showBulkActionModal('delete')"
                  class="text-left p-3 block font-semibold hover:bg-accentLight w-full">Delete users</button>
                <button @click="showBulkActionModal('activate')"
                  class="text-left p-3 block font-semibold hover:bg-accentLight w-full">Activate users</button>
                <button @click="showBulkActionModal('deactivate')"
                  class="text-left p-3 block font-semibold hover:bg-accentLight w-full">Deactivate users</button>
              </div>
            </div>
            <trac-button @button-clicked="openCreateNewUser">
              <div class="flex items-center justify-center">
                <svg class="w-4 h-4 mr-3" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="9.33333" cy="9.33333" r="8.33333" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M9.33366 6V12.6667" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M6 9.33366H12.6667" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>

                Add New User
              </div>
            </trac-button>
          </div>
        </div>
      </div>
      <div class="overflow-scroll mt-10">
        <table class="w-full table-auto">
          <thead class="bg-blue-100">
            <tr class="h-16 rounded-tl-sm">
              <th v-if="showingCheckboxes">
                <div class="p-4">
                  <input type="checkbox" @input="selectAll" :checked="!allSelected">
                </div>
              </th>
              <th class="text-left px-4 whitespace-no-wrap text-xs font-semibold py-2 uppercase text-accentDark">
                Name
              </th>
              <th class="text-left px-4 whitespace-no-wrap text-xs font-semibold py-2 uppercase text-accentDark">
                Role
              </th>
              <th class="text-left px-4 whitespace-no-wrap text-xs font-semibold py-2 uppercase text-accentDark">
                Locations
              </th>
              <th class="text-left px-4 whitespace-no-wrap text-xs font-semibold py-2 uppercase text-accentDark">
                Status
              </th>
              <th class="text-left px-4 whitespace-no-wrap text-xs font-semibold py-2 uppercase text-accentDark" v-if="users.length > 1 && !showingCheckboxes">
                Action
              </th>
            </tr>
          </thead>
          <tbody v-if="filteredUser.length > 0">
            <tr class="cursor-pointer even:bg-transparentBackground" :class="index % 2 === 0 ? '' : 'bg-gray-100'"
              v-for="(user, index) in filteredUser" :key="index">
              <td v-if="showingCheckboxes">
                <div class="p-4">
                  <input type="checkbox" v-model="user.isSelected">
                </div>
              </td>
              <td @click="sendtoEmployee(user)" class="text-xs py-5 whitespace-no-wrap px-4 text-accentDark font-medium">
                <div class="flex items-center capitalize">
                  <span>{{ user.first_name + " " + user.last_name }}</span>
                </div>
              </td>
              <td @click="sendtoEmployee(user)" class="capitalize text-xs py-5 whitespace-no-wrap px-4 text-accentDark font-bold">
                {{ user.role }}
              </td>
              <td @click="sendtoEmployee(user)" class="text-xs py-5 whitespace-no-wrap px-4 text-accentDark font-medium">
                <div v-if="user.stores" style="background-color: #EFEFEF;" class="rounded-2xl p-2 pr-0 inline-block">
                  <span class="capitalize pr-2 text-xs" v-for="(store, i) in user.stores.slice(0, 3)" :key="i">
                    {{ store.name || "main" }}
                  </span>
                  <span v-if="user.stores.length > 3" class="text-primaryBlue font-semibold pr-2">{{ '+' +
                  user.stores.slice(3).length + ' more' }}</span>
                </div>
              </td>
              <td @click="sendtoEmployee(user)" class="text-xs py-5 whitespace-no-wrap px-4 text-accentDark">
                <div class="flex flex-row font-semibold" :class="user.active ? 'text-green-500': 'text-red-500'">
                  {{ user.active ? "Activated" : "Deactivated" }}
                </div>
              </td>
              <td class="text-xs py-5 whitespace-no-wrap px-4 text-accentDark" v-if="users.length > 1 && !showingCheckboxes">
                <div class="flex flex-row font-semibold relative" v-click-outside="hideUserOptions">
                  <button @click.stop
                    class="border border-primaryBlue text-primaryBlue font-semibold py-2 px-4 z-10 rounded text-xs"
                    @click="showUserOptions($event, index)">
                    <span class="">Actions</span>
                    <div class="flex justify-between items-center">
                      <!-- <img src="./../../assets/svg/down_arrow.svg" alt="" class="block"> -->
                    </div>
                  </button>
                  <div v-if="currentSelectedUserDropdown === index" class="p-2 bg-white absolute z-20 shadow-md w-48"
                    style="top: 100%; right: 50%;">
                    <!-- <button class="text-left p-3 block font-semibold hover:bg-accentLight w-full"
                      @click="sendtoEmployee(user)">View</button> -->
                    <button @click="updateSingleUserStatus(user, user.active ? 'deactivate' : 'activate')" class="text-left p-3 block font-semibold hover:bg-accentLight w-full">{{user.active ? 'Deactivate': 'Activate'}}</button>
                    <button @click="updateSingleUserStatus(user, 'delete')" class="text-left p-3 block font-semibold hover:bg-accentLight w-full">Delete</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-xs font-medium p-4" colspan="7">
                <trac-center-data>
                  <div class="h-40 flex items-center text-lg text-gray-600">
                    No user added yet.
                  </div>
                </trac-center-data>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import { get } from "http";
import moment from "moment";
import ClickOutside from "vue-click-outside";
import { eventBus } from "./../../main";

export default {
  directives: {
    ClickOutside,
  },
  data() {
    return {
      moment,
      users: [],
      search: "",
      loading: false,
      confirmOp: false,
      currentSelectedUserDropdown: null,
      showingBulkUserDropdown: false,
      showingCheckboxes: false,
      currentOp: null,
    };
  },
  computed: {
    getOpActionText() {
      let opText;
      switch (this.currentOp) {
        case 'delete':
          opText = 'Once you delete these users, they will not be able to have access to your account.';
          break;
        case 'deactivate':
          opText = 'Once you deactivate this user, they will not be able to have access to your account anymore.';
          break;
        case 'activate':
          opText = 'Once you activate these users, they will have access to your account';
          break;
        default:
          opText = 'Once you deactivate this user, they will not be able to have access to your account anymore.';
          break;
      }
      return opText;
    },
    allSelected() {
      return this.filteredUser.find(user => {
        return !user.isSelected;
      });
    },
    userSelected() {
      return this.filteredUser.filter(user => {
        return user.isSelected;
      });
    },
    filteredUser() {
      return [...this.users].filter(
        (user) =>
          user.first_name
            .toLowerCase()
            .includes(this.search.trim().toLowerCase()) ||
          user.last_name
            .toLowerCase()
            .includes(this.search.trim().toLowerCase())
      );
    },
  },
  methods: {
    showBulkActionModal(action) {
      this.showingBulkUserDropdown = false;
      if (action === 'activate') {
        this.filterUsersToDeactivate();
      } else if (action === 'deactivate') {
        this.filterUsersToActivate();
      } else {
        this.filterAllUsers();
      }
      this.showingCheckboxes = true;
      this.currentOp = action;
    },
    filterAllUsers() {
      this.users = this.allUsersPlaceholder;
    },
    filterUsersToDeactivate() {
      this.users = this.allUsersPlaceholder.filter(user => !user.active);
    },
    filterUsersToActivate() {
      this.users = this.allUsersPlaceholder.filter(user => user.active);
    },
    updateSingleUserStatus(user, ops) {
      this.currentOp = ops;
      user.isSelected = true;
      this.confirmOp = true;
    },
    showConfirmOp() {
      if (this.userSelected.length === 0) {
      eventBus.$emit("trac-alert", { message: 'Please select at least one user.' });
        return;
      }
      this.confirmOp = true;
    },
    cancelOp() {
      this.filterAllUsers();
      this.showingCheckboxes = false;
    },
    selectAll(event) {
      this.users.forEach(user => user.isSelected = event.target.checked);
    },
    showUserOptions(event, index) {
      const target = event.target;
      this.currentSelectedUserDropdown = index;
      this.showingBulkUserDropdown = false;
    },
    toggleBulkUserDropdown() {
      this.showingBulkUserDropdown = !this.showingBulkUserDropdown;
    },
    hideUserOptions() {
      this.currentSelectedUserDropdown = null;
      this.showingBulkUserDropdown = false;
    },
    openCreateNewUser() {
      // this.$store.commit("setUserDetails", {});
      this.$router.push({ name: 'EmployeeDetailsUser' });
    },
    sendtoEmployee(x) {
      this.$store.commit("setUserDetails", x);
      this.$router.push({ name: "EmployeeMenu" });
    },
    getOpAction() {
      let op;
      switch (this.currentOp) {
        case 'delete':
          op = 'deleted';
          break;
        case 'deactivate':
          op = 'deactivated';
          break;
        case 'activate':
          op = 'active';
          break;
        default:
          op = 'deactivated';
          break;
      }
      return op;
    },
    async updateOps() {
      const payload = {
        ops: this.getOpAction(),
        users: this.filteredUser.filter(user => user.isSelected).map(user => {
          return {id: user._id};
        }),
      };
      this.loading = true;
      let res = await this.$store.dispatch("UPDATE_USER_OPS", payload);

      if (res.status) {
        this.users.forEach(user => {
          if (payload.users.find(u => u.id === user._id)) {
            if (this.currentOp === 'activate' && user.isSelected) {
              user.active = true;
            } else if (this.currentOp === 'deactivate' && user.isSelected) {
              user.active = false;
            }
            user.isSelected = false;
            this.confirmOp = false;
          }

          if (this.currentOp === 'delete') {
            this.users = this.users.filter(user => !user.isSelected);
          }
        });
      }
      eventBus.$emit("trac-alert", { message: res.message });
      this.cancelOp();
      this.loading = false;
    },
  },
  async created() {
    this.loading = true;
    let res = await this.$store.dispatch("FETCH_USERS");
    // let res = await this.$store.dispatch("FETCH_ALL_USERS");
    if (res.status) {
      this.users = (res.data || []).map(user => {
        return { ...user, isSelected: false };
      }).sort((a, b) =>
        (a.first_name + " " + a.last_name).trim().toLowerCase() >
          (b.first_name + " " + b.last_name).trim().toLowerCase()
          ? 1
          : -1
      );

      this.allUsersPlaceholder = this.users;
    } else {
      eventBus.$emit("trac-alert", { message: res.message });
    }
    this.loading = false;
  },
};
</script>

<style>

</style>
